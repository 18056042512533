<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcChurchMinistry }}</h1>
    </header>
    <!-- / Page Header -->
    <security-banner v-if="!allowPage" :i18n="translations.components" />
    <div v-if="allowPage">
      <camp-selector @camp_changed="page_load()" :tooltip="translations.tcToolTip" :i18n="translations.components" />
      <!-- / Camp Selector -->

      <div class="page-body container mx-med">
        <section class="section-1">
          <h4>{{ translations.tcRecentChurchVisits }}</h4>
          <div class="row">
            <div class="col col-6">
              <div v-if="this.visits.length == 0">
                {{ translations.tcNoChurchVisitsMessage }}
              </div>
              <div v-else>
                <DateCard v-for="(visit, index) in this.visits" :key="index" :obj="visit.date"
                  :i18n='translations.common'>
                  <BasicDateCard v-if="translations.common" :i18n="translations.components" :obj="{
                    title: visit.title,
                    description: visit.description,
                    schedule: visit.schedule,
                    contact_type: visit.contactTypeGuid,
                    visit_key: visit.visit_key,
                    entity_key: visit.entity_key,
                    destination: visit.destination,
                  }" />
                </DateCard>
              </div>
              <div class="mt-3">
                <button v-if="iCanSee(secured_note_add_controls.add_church_visit_note_button)"
                  @click="handleMoreVisitClick" class="btn btn-primary btn-w-med mt-3 mb-3">
                  {{ translations.tcViewMore }}
                </button>
                &nbsp;
                <button v-if="iCanSee(secured_note_add_controls.add_church_visit_note_button)"
                  @click="handleAddVisitClick" class="btn btn-primary btn-w-med mt-3 mb-3">
                  {{ translations.tcAddVisit }}
                </button>
              </div>
            </div>
            <div class="col col-6">
              <NumberCard v-for="(stat, index) in topWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-2">
          <h4>{{ translations.tcUpcomingChurchPresentations }}</h4>
          <div class="row">
            <div class="col col-6">
              <div v-if="this.scheduledPresentations.length == 0">
                {{ translations.tcNoPresentationsMessage }}
              </div>
              <div v-else>
                <DateCard v-for="(prs, index) in this.scheduledPresentations" :key="index" :obj="prs.date"
                  :i18n='translations.common'>
                  <BasicDateCard v-if="translations.common" :i18n="translations.components" :obj="{
                    title: prs.title,
                    description: prs.description,
                    schedule: prs.schedule,
                    churchServiceTypeGuid: prs.churchServiceTypeGuid,
                    weekdayGuid: prs.date.weekdayGuid,
                    presentation_key: prs.presentation_key,
                    destination: prs.destination,
                  }" />
                </DateCard>
              </div>
              <div class="mt-3">
                <router-link to="/programs/cm/presentations" class="btn btn-primary btn-w-med mr-4 mt-3 mb-3">
                  <div>{{ translations.tcViewMore }}</div>
                </router-link>&nbsp;
                <button v-if="iCanSee(secured_presentation_add_controls.add_church_presentation_button)"
                  @click="handleAddPresentationClick" class="btn btn-primary btn-w-med mt-3 mb-3">
                  {{ translations.tcAddPresentation }}
                </button>
              </div>
            </div>
            <div class="col col-6">
              <NumberCard v-for="(stat, index) in bottomWidgetData" :key="index" :obj="stat"
                :i18n="translations.components" />
            </div>
          </div>
        </section>
        <section class="section-3">
          <div class="row">
            <InfoCard v-for="(info, index) in churchMinistryInfo" :key="index" :obj="info"
              @set_program="handleSetProgram($event)" />
          </div>
        </section>
      </div>
      <!-- / Page Body -->
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'
import BasicDateCard from '@/components/date-card/Basic.vue'
import CampSelect from '@/components/CampSelect.vue'
import DateCard from '@/components/date-card/DateCard.vue'
import InfoCard from '@/components/info-card/InfoCard.vue'
import NumberCard from '@/components/number-card/NumberCard.vue'
import SecurityBanner from '@/components/SecurityBanner'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'church-ministry',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
      churchMinistryInfo: [],
      secured_presentation_add_controls: {
        add_church_presentation_button: '2e18fcc4-b81d-4b54-804e-9d9e8932d810',
      },
      secured_note_add_controls: {
        add_church_visit_note_button: '5e3dec43-8a2e-4c16-a09c-b00c1c6a5a80',
      },
    }
  },
  components: {
    BasicDateCard: BasicDateCard,
    campSelector: CampSelect,
    DateCard: DateCard,
    InfoCard: InfoCard,
    NumberCard: NumberCard,
    securityBanner: SecurityBanner,
  },
  methods: {
    ...mapActions({
      clearSingleVisit: 'churchMinistry/clearSingleVisit',
      clearSinglePresentation: 'churchMinistry/clearSinglePresentation',
      loadWidgetData: 'churchMinistry/loadWidgetData',
      setLoadingStatus: 'menu/setLoadingStatus',
      setRouterBackLink: 'user/setRouterBackLink',
      setSelectedChurchKey: 'user/setSelectedChurchKey',
      setSelectedVisitKey: 'churchMinistry/setSelectedVisitKey',
      setPresentationEditKey: 'churchMinistry/setPresentationEditKey',
      setMRPProgramId: 'mrp/setMRPProgramId',
    }),
    loadChurchMinistryInfo() {
      this.churchMinistryInfo = [
        {
          class: 'col col-6',
          title: `${this.translations.tcChurchListTitle}`,
          content: `${this.translations.tcChurchListContent}`,
          link: '/programs/cm/church-list',
          label: `${this.translations.tcViewMore}`,
          img: {
            src: 'svgs/church-list.svg',
            alt: `${this.translations.tcChurchListTitle}`,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: `${this.translations.tcChurchPresentersTitle}`,
          content: `${this.translations.tcChurchPresentersContent}`,
          link: '/programs/cm/presenters',
          label: `${this.translations.tcViewMore}`,
          img: {
            src: 'svgs/church-presenters.svg',
            alt: `${this.translations.tcChurchPresentersTitle}`,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: `${this.translations.tcChurchReportsTitle}`,
          content: `${this.translations.tcChurchReportsContent}`,
          link: '/reports/report-center/cm',
          label: `${this.translations.tcViewMore}`,
          img: {
            src: 'svgs/church-reports.svg',
            alt: `${this.translations.tcChurchReportsTitle}`,
            width: 64,
            height: 64,
          },
        },
        {
          class: 'col col-6',
          title: `${this.translations.tcProgramResourcesTitle}`,
          content: `${this.translations.tcProgramResourcesContent}`,
          emitlink: '/resources/media-portal/program',
          itemId: '1b3a2cd6-63d0-4050-891f-54549c33b7a8',
          label: `${this.translations.tcViewMore}`,
          img: {
            src: 'svgs/program-resources.svg',
            alt: `${this.translations.tcProgramResourcesTitle}`,
            width: 64,
            height: 64,
          },
        },
      ]
    },
    async page_load() {
      await Promise.all([
        this.setLoadingStatus(true),
        await this.getTranslations(),
        this.getComponentTranslations('basic-date-card', 'camp-select', 'date-card', 'number-card', 'security-banner'),
        await this.getComponentTranslations(
          'common.church-service-type',
          'common.church-visit-types',
          'common.days',
          'common.months-abbreviations' ),
        this.loadChurchMinistryInfo(),
        await this.loadWidgetData(),
      ]).then(results => {
        this.stripReadableText(results[3])
        const translatedText = {
          ...results[2],
          common: { ...this.translations.common }
        }
        this.$set(this.translations, 'components', translatedText)
      })
      .catch((error) => {
        console.error(`There was an error encountered while loading page in view ${this.$options.name}: ${error}`)
      })
      .finally(() => {
        this.setLoadingStatus(false)
      })
    },
    async handleAddVisitClick() {
      await Promise.all([
        await this.clearSingleVisit(),
        await this.setSelectedVisitKey(null),
        await this.setSelectedChurchKey(''),
      ]).then(() => {
        this.setRouterBackLink(this.$route.name)
        this.$router.push({
          path: '/programs/cm/churchProfile/add-church-visit',
        })
      })
    },
    async handleMoreVisitClick() {
      await Promise.all([
        await this.clearSingleVisit(),
        await this.setSelectedVisitKey(null),
        await this.setSelectedChurchKey(''),
      ]).then(() => {
        this.setRouterBackLink(this.$route.name)
        this.$router.push({
          path: '/programs/cm/church-visits',
        })
      })
    },
    async handleAddPresentationClick() {
      await Promise.all([
        await this.setPresentationEditKey(null),
        await this.clearSinglePresentation(),
        await this.setSelectedChurchKey(''),
      ]).then(() => {
        this.$router.push({
          path: '/programs/cm/churchProfile/add-church-presentation',
        })
      })
    },
    async handleSetProgram(data) {
      this.setMRPProgramId(data.itemId)
      this.$router.push(data.emitlink)
    },
  },
  computed: {
    ...mapGetters({
      bottomWidgetData: 'churchMinistry/bottomWidgetData',
      held_meetings_by_camp: 'churchMinistry/held_meetings_by_camp',
      held_visits_by_camp: 'churchMinistry/held_visits_by_camp',
      iCanSee: 'user/iCanSee',
      isInMenu: 'menu/isInMenu',
      prefCulture: 'user/userPreferredCulture',
      presentations_scheduled_by_camp: 'churchMinistry/presentations_scheduled_by_camp',
      topWidgetData: 'churchMinistry/topWidgetData',
    }),
    allowPage() {
      return this.isInMenu(this.$route.path)
    },
    scheduledPresentations() {
      let churchPresentations = []
      if (!this.presentations_scheduled_by_camp) {
        return [
          {
            date: { month: '', day: '', year: '' },
            title: '',
            description: '',
            schedule: '',
          },
        ]
      } else {
        churchPresentations = this.presentations_scheduled_by_camp.map((meeting, idx) => {
          return {
            date: {
              day: meeting.day,
              month: meeting.month,
              monthGuid: meeting.month_guid,
              weekdayGuid: meeting.weekday_guid,
              year: meeting.year,
            },
            title: meeting.location_name,
            description: meeting.speaker,
            schedule: meeting.service_time_description,
            churchServiceTypeGuid: meeting.church_service_type_guid || '',
            presentation_key: meeting.mtg_key,
            destination: '/programs/cm/churchProfile/view-church-presentation',
          }
        })
      }
      return churchPresentations
    },
    visits() {
      let churchVisits = []
      if (!this.held_visits_by_camp) {
        return [
          {
            date: { month: '', day: '', year: '' },
            title: 'loading',
            description: '',
            schedule: '',
          },
        ]
      } else {
        churchVisits = this.held_visits_by_camp.map((visit, idx) => {
          return {
            date: {
              month: visit.month,
              monthGuid:visit.month_guid,
              day: visit.day,
              year: visit.year,
            },
            contactTypeGuid: visit.nte_ctt_key,
            title: visit.church_name,
            description: visit.name,
            schedule: visit.contact_type,
            visit_key: visit.nte_key,
            entity_key: visit.nte_entity_key,
            destination: '/programs/cm/churchProfile/view-church-visit',
          }
        })
      }
      return churchVisits
    },
  },
}
</script>

<style lang="scss">
@import '@/styles/settings.scss';
@import '@/styles/bootstrap/b-button.scss';

.church-ministry {
  .page-body {
    margin-top: 68px;

    @include breakpoint(sm) {
      margin-top: 3rem;
    }

    h4 {
      margin-bottom: 36px;
    }
  }

  .section-1,
  .section-2 {
    margin-bottom: 80px;

    @include breakpoint(sm) {
      margin-bottom: 3rem;
    }

    >.row {
      margin-bottom: 28px;
    }

    .col {
      &:first-of-type {
        padding-right: 23px;
      }

      &:last-of-type {
        padding-left: 23px;
      }

      @include breakpoint(sm) {
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }

  .section-3 {
    margin-bottom: 60px;

    .col {
      &:nth-child(2n-1) {
        padding-right: 23px;
      }

      &:nth-child(2n) {
        padding-left: 23px;
      }
    }

    @include breakpoint(sm) {
      .col {
        height: 100%;
        max-width: none;
        width: 100%;
        flex: 0 0 100%;
        padding-left: 15px !important;
        padding-right: 15px !important;
      }
    }
  }
}
</style>
